<template>
    <NdxFlyIn v-if="showFilter" class="limit500">
        <template #title>
            {{ $t('label.filterHeadline') }}
        </template>
        <template #default>
            <div class="d-flex gap-3 flex-column">
                <NdxSearchInput
                    class="w-100"
                    variant="secondary"
                    :model-value="searchTextInput"
                    label=""
                    @on-key-enter="(value) => searchTextInput = value"
                />
                <NdxSelect
                    v-if="timeRangeOptions"
                    variant="secondary"
                    v-model="timeRange"
                    :options="timeRangeOptions"
                    label=""
                    class="dropdown-menu-start"
                    text-wrap
                />
                <slot name="customStateSelect">
                    <NdxMultiSelect
                        v-if="stateListOptions"
                        variant="secondary"
                        v-model="stateList"
                        :options="stateListOptions"
                        label=""
                        :readonly="stateListOptions.length < 2"
                        text-wrap
                    />
                </slot>
                <template v-if="startDate">
                    <div class="dateLabel">{{ $t('label.requestedAt') }}</div>
                    <NdxDatePicker
                        variant="secondary"
                        :label="$t('label.dateFrom')"
                        v-model="startDateFrom"
                        :set-noon="false"
                        :maxDate="startDateTo"
                        clear-button
                    />
                    <NdxDatePicker
                        variant="secondary"
                        :label="$t('label.dateTo')"
                        v-model="startDateTo"
                        :set-noon="false"
                        :minDate="startDateFrom"
                        clear-button
                    />
                </template>
                <template v-if="endDate">
                    <div class="dateLabel">{{ $t('label.endedAt') }}</div>
                    <NdxDatePicker
                        variant="secondary"
                        :label="$t('label.dateFrom')"
                        v-model="endDateFrom"
                        :set-noon="false"
                        :maxDate="endDateTo"
                        clear-button
                    />
                    <NdxDatePicker
                        variant="secondary"
                        :label="$t('label.dateTo')"
                        v-model="endDateTo"
                        :set-noon="false"
                        :minDate="endDateFrom"
                        clear-button
                    />
                </template>
            </div>
        </template>
        <template #buttons>
            <NdxButtonLink
                class="btnFlex"
                @click="clearFilter"
            >
                {{ $t('btn.reset') }}
            </NdxButtonLink>
            <NdxButton class="btnFlex" @click="$emit('update:showFilter', false)">
                {{ $t('btn.close') }}
            </NdxButton>
        </template>
    </NdxFlyIn>
</template>

<script>
    import NdxButton from "./NdxButton.vue";
    import NdxMultiSelect from "./formElements/NdxMultiSelect.vue";
    import NdxSearchInput from "./formElements/NdxSearchInput.vue";
    import NdxSelect from "./formElements/NdxSelect.vue";
    import NdxFlyIn from "./NdxFlyIn.vue";
    import NdxButtonLink from "./NdxButtonLink.vue";
    import NdxDatePicker from "./formElements/NdxDatePicker.vue";

    export default {
        name: "NdxListFilter",
        components: {NdxDatePicker, NdxButtonLink, NdxFlyIn, NdxButton, NdxSelect, NdxMultiSelect, NdxSearchInput},
        emits: ['filter-triggered', 'clear-filter', 'update:showFilter'],
        props: {
            showFilter: {
                type: Boolean
            },
            selectedTimeRange: {
                type: [String, Number]
            },
            timeRangeOptions: {
                type: Array
            },
            statesSelected: {
                type: Array
            },
            stateListOptions: {
                type: Array
            },
            searchText: {
                type: String
            },
            startDate: {
                type: Object
            },
            endDate: {
                type: Object
            }
        },
        computed: {
            timeRange: {
                get() {
                    return this.selectedTimeRange;
                },
                set(value) {
                    this.updateFilter(
                        value,
                        this.stateList,
                        this.searchTextInput,
                        this.startDateFrom,
                        this.startDateTo,
                        this.endDateFrom,
                        this.endDateTo
                    );
                }
            },
            stateList: {
                get() {
                    return this.statesSelected;
                },
                set(value) {
                    this.updateFilter(
                        this.timeRange,
                        value,
                        this.searchTextInput,
                        this.startDateFrom,
                        this.startDateTo,
                        this.endDateFrom,
                        this.endDateTo
                    );
                }
            },
            searchTextInput: {
                get() {
                    return this.searchText;
                },
                set(value) {
                    this.updateFilter(
                        this.timeRange,
                        this.stateList,
                        value,
                        this.startDateFrom,
                        this.startDateTo,
                        this.endDateFrom,
                        this.endDateTo
                    );
                }
            },
            startDateFrom: {
                get() {
                    return this.startDate?.from ?? null;
                },
                set(value) {
                    this.updateFilter(
                        this.timeRange,
                        this.stateList,
                        this.searchTextInput,
                        value,
                        this.startDateTo,
                        this.endDateFrom,
                        this.endDateTo
                    );
                }
            },
            startDateTo: {
                get() {
                    return this.startDate?.to ?? null;
                },
                set(value) {
                    this.updateFilter(
                        this.timeRange,
                        this.stateList,
                        this.searchTextInput,
                        this.startDateFrom,
                        value,
                        this.endDateFrom,
                        this.endDateTo
                    );
                }
            },
            endDateFrom: {
                get() {
                    return this.endDate?.from ?? null;
                },
                set(value) {
                    this.updateFilter(
                        this.timeRange,
                        this.stateList,
                        this.searchTextInput,
                        this.startDateFrom,
                        this.startDateTo,
                        value,
                        this.endDateTo
                    );
                }
            },
            endDateTo: {
                get() {
                    return this.endDate?.to ?? null;
                },
                set(value) {
                    this.updateFilter(
                        this.timeRange,
                        this.stateList,
                        this.searchTextInput,
                        this.startDateFrom,
                        this.startDateTo,
                        this.endDateFrom,
                        value
                    );
                }
            }
        },
        methods: {
            updateFilter(
                timeRange,
                stateList,
                searchText,
                startDateFrom,
                startDateTo,
                endDateFrom,
                endDateTo
            ) {
                this.$emit('filter-triggered', {
                    timeRange: timeRange,
                    stateList: stateList,
                    searchText: searchText,
                    startDateFrom: startDateFrom,
                    startDateTo: startDateTo,
                    endDateFrom: endDateFrom,
                    endDateTo: endDateTo
                });
            },
            clearFilter() {
                this.$emit('clear-filter');
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "../../style/variables_bootstrap";
    @import "../../style/variables_ndx";

    .dateLabel {
        @extend %font-body2;

        border-bottom: 1px solid var(--bs-gray-middle);
        padding-top: 16px;
        padding-bottom: 8px;
    }
</style>
