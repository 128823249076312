<template>
    <div class="ndx-tree-nav-widget" v-if="initiated">
        <div
            class="rootNode"
            :class="{readonly: this.isDataCheck}"
            @click="onRootClick"
        >
            <span class="node-text">
                {{ isDataCheck ? $t('label.dataCheck') : $t('label.myAccount') }}
            </span>
        </div>
        <div
            v-if="showUserTab"
            class="childNode"
            :class="{active: userIsActive, readonly: this.isDataCheck}"
            @click="onUserClick"
        >
            <span>{{ getLabel('User', this) }}</span>
        </div>
        <div
            v-if="showClientTab"
            class="childNode"
            :class="{active: clientIsActive, readonly: this.isDataCheck}"
            @click="onClientClick"
        >
            <span>{{ getLabel('Client', this) }}</span>
        </div>
        <div
            v-if="!isDataCheck"
            class="childNode"
            :class="{active: addressesIsActive}"
            @click="onAddressesClick"
        >
            <span>{{ getLabel('Addresses', this) }}</span>
        </div>
        <div
            v-if="!isDataCheck && showUserSettings"
            class="childNode"
            :class="{active: userSettingsIsActive}"
            @click="onUserSettingsClick"
        >
            <span>{{ getLabel('Settings', this) }}</span>
        </div>
        <div
            v-if="!isDataCheck && pageIsActive('myAccountSubstitutes')"
            class="childNode"
            :class="{active: substitutesIsActive}"
            @click="onSubstitutesClick"
        >
            <span>{{ getLabel('Substitutes', this) }}</span>
        </div>
        <div
            v-if="!isDataCheck && pageIsActive('myAccountAbsence')"
            class="childNode"
            :class="{active: absenceIsActive}"
            @click="onAbsenceClick"
        >
            <span>{{ getLabel('Absence', this) }}</span>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapState } from "vuex";

    export default {
        name: 'MyAccountNavWidget',
        props: {
            widget: Object
        },
        computed: {
            ...mapState({
                initiated: state => state.myAccount.initiated,
                activeTab: state => state.myAccount.activeTab,
                sysMailActiveStates: state => state.shop.sysMailActiveStates
            }),
            ...mapGetters('myAccount', [
                'showUserTab',
                'showClientTab',
                'getLabel'
            ]),
            ...mapGetters('session', [
                'authIdIsNdx'
            ]),
            isDataCheck() {
                return this.widget.config.isDataCheck === true;
            },
            userIsActive() {
                if (this.isDataCheck) {
                    return this.activeTab === 'user';
                }
                return this.$route.name === 'MyAccountUser';
            },
            clientIsActive() {
                if (this.isDataCheck) {
                    return this.activeTab === 'client';
                }
                return this.$route.name === 'MyAccountClient';
            },
            addressesIsActive() {
                return this.$route.name === 'MyAccountAddresses';
            },
            userSettingsIsActive() {
                return this.$route.name === 'MyAccountUserSettings';
            },
            substitutesIsActive() {
                return this.$route.name === 'MyAccountSubstitutes';
            },
            absenceIsActive() {
                return this.$route.name === 'MyAccountAbsence';
            },
            showUserSettings() {
                if (this.sysMailActiveStates !== null) {
                    for (let state in this.sysMailActiveStates) {
                        if (this.sysMailActiveStates[state]) {
                            return true;
                        }
                    }
                }
                return this.authIdIsNdx;
            }
        },
        methods: {
            onRootClick() {
                if (!this.isDataCheck) {
                    this.$router.push({name: 'MyAccount'});
                }
            },
            onUserClick() {
                if (!this.isDataCheck) {
                    this.$router.push(this.$store.getters['shop/getRouteByType']('myAccountUser'));
                }
            },
            onClientClick() {
                if (!this.isDataCheck) {
                    this.$router.push(this.$store.getters['shop/getRouteByType']('myAccountClient'));
                }
            },
            onAddressesClick() {
                this.$router.push(this.$store.getters['shop/getRouteByType']('myAccountAddresses'));
            },
            onUserSettingsClick() {
                const route = this.$store.getters['shop/getRouteByType']('myAccountSettings');
                this.$router.push(route);
            },
            onSubstitutesClick() {
                const route = this.$store.getters['shop/getRouteByType']('myAccountSubstitutes');
                this.$router.push(route);
            },
            onAbsenceClick() {
                const route = this.$store.getters['shop/getRouteByType']('myAccountAbsence');
                this.$router.push(route);
            },
            pageIsActive(wgcType) {
                const wgc = this.$store.getters['shop/getWgcByType'](wgcType);
                return !!wgc;
            }
        }
    };
</script>
