import orderApi from "../../api/order";
import outputHandler from "../../api/outputHandler";
import RequestCollector from "./base/requestCollector";

let downloadableFilesCollector = null;

//state
const state = {
    order: null
};

// getters
const getters = {
    getSumNet(state) {
        let result = 0.0;
        if (state.order) {
            state.order.orderItems.map((orderItem) => {
                result += parseFloat(orderItem.priceNet);
            });
        }
        return result;
    },
    getSumAdditionalCostsNet(state) {
        let result = 0.0;
        if (state.order) {
            state.order.orderItems.map((orderItem) => {
                if (orderItem.additionalPriceNet && parseFloat(orderItem.additionalPriceNet) > 0) {
                    result += parseFloat(orderItem.additionalPriceNet);
                }
            });
        }
        return result;
    },
    getSumTax(state) {
        const result = state.order?.tax ?? 0;
        return parseFloat(result);
    },
    getSumGross(state) {
        const result = state.order?.priceGross ?? 0;
        return parseFloat(result);
    },
    getSumDeliveryCostsNet(state) {
        const result = state.order?.deliveryCost ?? 0;
        return parseFloat(result);
    },
    getRelevantSum: (state, getters) => (showGross, isNetClient) => {
        let relevantSum = 0.0;

        if (showGross && !isNetClient) {
            relevantSum = getters.getSumGross;
        } else {
            relevantSum = getters.getSumNet + getters.getSumAdditionalCostsNet + getters.getSumDeliveryCostsNet;
        }

        let budgetValues = 0.0;
        getters.getPriceRelevantBudgets.map((budget) => {
            budgetValues += parseFloat(budget.value);
        });

        relevantSum -= budgetValues;
        relevantSum = relevantSum < 0 ? 0 : relevantSum;

        return parseFloat(relevantSum);
    },
    getPriceRelevantBudgets(state) {
        return state.order?.budgets?.filter((budget) => {
            return budget.modus === 'gift';
        }) ?? [];
    },
    getProductCount(state) {
        return state.order?.products.length ?? 0;
    },
    isOrderable() {
        return false;
    },
    hasPrintView() {
        return false;
    },
    isMultiAddressDelivery() {
        return state.order?.deliveryContacts.length > 1;
    },
    getAddress(state) {
        return (addressId) => {
            const dc = state.order?.deliveryContacts.find(deliveryContact => deliveryContact.address.id == addressId);
            return dc?.address;
        };
    },
    getVendor(state) {
        return (vendorId) => {
            return state.order?.vendors.find(vendor => vendor.id == vendorId);
        };
    },
    getProduct(state) {
        return (productId) => {
            return state.order?.products.find(product => product.id == productId);
        };
    },
    getBudget(state) {
        return (budgetId) => {
            return state.order?.budgets.find(product => product.id == budgetId);
        };
    },
    getOrderItemsByGroup(state) {
        return (group) => {
            return state.order?.orderItems.filter(
                orderItem => orderItem.deliveryContacts.some(
                    deliveryContact => deliveryContact.groupHash === group.groupHash
                )
            );
        };
    },
    getBudgetsByGroup(state) {
        return (group) => {
            return state.order?.budgets.filter(budget => group.budgetIdList.some(budgetId => budgetId == budget.id));
        };
    },
    getDeliveryOptionByPricelistId(state) {
        return (priceListId) => {
            return state.order?.deliveryOptions.find(
                deliveryOption => deliveryOption.pricelists.some(
                    pricelist => pricelist.deliveryOptionPriceListId == priceListId
                )
            );
        };
    },
    getCheckoutData(state) {
        return {
            deliveryAddress: state.order?.deliveryContacts[0].address,
            invoiceAddress: state.order?.billingContact.address,
        };
    },
    getCurrency(state) {
        return state.order?.currency;
    },
    getDeliveryAddressShipment(state) {
        return (addressId) => {
            return state.order?.deliveryContacts.find((dc) => dc.address.id == addressId)?.shipments ?? [];
        };
    },
    getOrderItems(state) {
        return state.order ? state.order.orderItems : [];
    },
    getSummaryBudgets(state) {
        let list = [];

        if (state.order) {
            for (let i in state.order.budgets) {
                list.push({
                    name: state.order.budgets[i].name,
                    reducingValue: state.order.budgets[i].value,
                    remainingValue: 0
                });
            }

        }

        return list;
    }
};

// actions
const actions = {
    getOrders(ctx, {filterCriteria, offset, limit}) {
        return new Promise((resolve, reject) => {
            orderApi.filterBy(filterCriteria, offset, limit)
                .then((list) => {
                    resolve(list);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    reset({commit}) {
        commit('RESET');
    },
    find({commit}, data) {
        return new Promise((resolve, reject) => {
            orderApi.find(data.orderId)
                .then((data) => {
                    commit('SET_ORDER', data);
                    resolve(data);
                })
                .catch((error) => {
                    console.error(error);
                    reject(error);
                });
        });
    },
    getDownloadableFiles(ctx, {orderItemId}) {
        if (!downloadableFilesCollector) {
            downloadableFilesCollector = new RequestCollector(orderApi.getDownloadableFilesBulk);
        }

        return downloadableFilesCollector.doRequest([orderItemId]);
    },
    getVCardDataByProjectRunIds(ctx, data) {
        return new Promise((resolve, reject) => {
            outputHandler.getVCardDataByProjectRunIds(data.projectRunIds)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getOrderHistoryByProductAndDateRange(ctx, data) {
        return new Promise((resolve, reject) => {
            orderApi.getOrderHistoryByProductAndDateRange(data.list)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
};

// mutations
const mutations = {
    RESET(state) {
        state.order = null;
    },
    SET_ORDER(state, order) {
        state.order = order;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
