<template>
    <template v-if="initiated">
        <NdxPageHeader
            hide-filter
            v-model:view-mode="viewMode"
            view-mode-storage-key="myAccountViewMode"
            :nav-name="$t('label.myAccount')"
            :breadcrumbs="[{label: $t('label.myAccount')}]"
        />
        <div
            class="card-container"
            :class="{['viewmode-' + viewMode]: true}"
        >
            <NdxCategoryCard
                v-if="showUserTab"
                :title="getLabel('User', this)"
                card-icon="users"
                :variant="viewMode"
                @click="$router.push($store.getters['shop/getRouteByType']('myAccountUser'))"
            />
            <NdxCategoryCard
                v-if="showClientTab"
                :title="getLabel('Client', this)"
                card-icon="clients"
                :variant="viewMode"
                @click="$router.push($store.getters['shop/getRouteByType']('myAccountClient'))"
            />
            <NdxCategoryCard
                :title="getLabel('Addresses', this)"
                card-icon="office"
                :variant="viewMode"
                @click="$router.push($store.getters['shop/getRouteByType']('myAccountAddresses'))"
            />
            <NdxCategoryCard
                :title="getLabel('Settings', this)"
                card-icon="settings"
                :variant="viewMode"
                @click="$router.push($store.getters['shop/getRouteByType']('myAccountSettings'))"
            />
            <NdxCategoryCard
                v-if="pageIsActive('myAccountSubstitutes')"
                :title="getLabel('Substitutes', this)"
                card-icon="users"
                :variant="viewMode"
                @click="$router.push($store.getters['shop/getRouteByType']('myAccountSubstitutes'))"
            />
            <NdxCategoryCard
                v-if="pageIsActive('myAccountAbsence')"
                :title="getLabel('Absence', this)"
                card-icon="marketing-planner"
                :variant="viewMode"
                @click="$router.push($store.getters['shop/getRouteByType']('myAccountAbsence'))"
            />
        </div>


    </template>
</template>

<script>
    import NdxCategoryCard from "../library/NdxCategoryCard";
    import { mapGetters, mapState } from "vuex";
    import NdxPageHeader from "../library/NdxPageHeader.vue";

    export default {
        name: 'MyAccount',
        components: {NdxPageHeader, NdxCategoryCard},
        data() {
            return {
                viewMode: 'card'
            };
        },
        computed: {
            ...mapState({
                initiated: state => state.myAccount.initiated
            }),
            ...mapGetters('myAccount', [
                'showUserTab',
                'showClientTab',
                'getLabel'
            ]),
            ...mapGetters('session', [
                'authIdIsNdx'
            ])
        },
        created() {
            this.$store.dispatch('myAccount/getDataCheckConfig');
        },
        methods: {
            pageIsActive(wgcType) {
                const wgc = this.$store.getters['shop/getWgcByType'](wgcType);
                return !!wgc;
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import "~bootstrap/scss/bootstrap-utilities";

    .card-container.viewmode-list {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
    }

    .card-container.viewmode-card {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
        gap: 16px;
    }

    @include media-breakpoint-up(md) {
        .card-container.viewmode-card {
            grid-template-columns: repeat(auto-fill, minmax(222px, 1fr));
        }
    }
</style>
